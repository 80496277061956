<template>
	<a-modal v-model="show" :title="formData.questionId ? '修改答题信息' : '添加答题信息'" :width="700" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-form-model ref="formRef" :model="formData" :rules="formRule">
			<a-row :gutter="15">
				<a-col span="24">
					<a-form-model-item label="题目" prop="questionTitle" label-position="top">
						<Editor v-model="formData.questionTitle"></Editor>
						<!-- <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" v-model="formData.questionTitle"
                        placeholder="请输入题目"></a-textarea> -->
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="可获得积分" prop="score" label-position="top">
						<a-input-number style="width: 100%" :min="1" v-model="formData.score" placeholder="请输入可获得积分"></a-input-number>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="类型" prop="categoryId" label-position="top">
						<a-select show-search  placeholder="请选择题目类型" v-model="formData.categoryId" option-filter-prop="children" style="width: 200px" :filter-option="filterOption">
							<a-select-option v-for="item in categoryList" :key="item.categoryId" :value="item.categoryId">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-checkbox-group @change="onChange" v-model="formData.correctAnswer">
					<a-col v-for="(item, index) in formData.questionText" :key="index" :span="12">
						<a-form-model-item :label="`选项${zm[index]}`" label-position="top">
							<div style="display: flex; gap: 20px; align-items: center">
								<a-input v-model="formData.questionText[index]" placeholder="请输入选项"></a-input>
								<a-tooltip title="勾选的为正确答案，勾选一个即为单选">
									<a-checkbox :value="zm[index]"></a-checkbox>
								</a-tooltip>
								<a-tooltip title="删除">
									<a-icon @click="del(index)" type="delete" />
								</a-tooltip>
							</div>
						</a-form-model-item>
					</a-col>
				</a-checkbox-group>
			</a-row>
			<a-row :gutter="15">
				<a-col span="8">
					<a-button @click="formData.questionText.push('')">添加选项</a-button>
				</a-col>
			</a-row>
		</a-form-model>
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import upImg from '@/components/common/upImg.vue';
import Editor from '@/components/component/editor.vue';
export default {
	components: { upImg, Editor },
	data() {
		return {
			show: false,
			status: true,
			loading: false,
			categoryList: [],
			formData: {
				questionId: '',
				questionTitle: '',
				questionText: ['', ''],
				questionType: 0,
				correctAnswer: [],
				score: '',
				categoryId: ''
			},
			formRule: {
				questionTitle: [{ required: true, message: '请输入题目', trigger: 'blur' }],
				score: [{ required: true, message: '请输入积分', trigger: 'blur' }],
				categoryId: [{ required: true, message: '请选择题目分类', trigger: 'blur' }]
			},
			zm: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
		};
	},
	methods: {
		...apiUtil,
		onChange(e) {
			console.log(e);
		},
		del(index) {
			if (this.formData.questionText.length > 2) {
				this.formData.questionText.splice(index, 1);
			} else {
				utils.error('至少两个选项');
			}
		},
		_show(data) {
			this.show = true;
      this.getList()
			this.$nextTick(() => {
				if (data) {
					// console.log(id);
					// this.formData.id = 3;
					console.log(data);
					delete data.createTime;
					this.formData = data;
				} else {
					this.formData.questionId = '';
					this.formData.categoryId = '';
					this.formData.questionTitle = '';
					this.formData.questionText = ['', ''];
					this.formData.questionType = 0;
					this.formData.correctAnswer = [];
					this.formData.score = '';
				}
			});
		},
		_close() {
			this.$refs.formRef.resetFields();
			this.show = false;
			this.$emit('success');
		},
		getList() {
			this.http.get(`/platform/QuestionBank/categoryList`).then(ret => {
				this.categoryList = ret.data;
			});
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		},
		doSubmit() {
			console.log(this.formData.questionText);
			this.formData.questionText = this.formData.questionText.filter(item => item != '');
			if (this.formData.questionText.length < 2) {
				utils.error('至少两个选择项');
				return;
			}
			if (this.formData.correctAnswer.length == 0) {
				utils.error('请至少选择一个正确答案');
				return;
			}
			this.$refs.formRef.validate(valid => {
				if (valid) {
					utils.showSpin();
					request.post('/platform/QuestionBank/save', this.formData).then(ret => {
						this.$refs.formRef.resetFields();
						this.show = false;
						utils.success('操作成功！').then(() => {
							this.show = false;
							this.$emit('success');
						});
					});
				}
			});
		}
	}
};
</script>

<style scoped>
.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}
</style>
